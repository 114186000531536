.avatarPaper .MuiContainer-root
{
	text-align: center;
}

.avatarPaper .MuiContainer-root .MuiGrid-spacing-xs-3
{
  width: calc(100% + 12px);
}

.avatarPaper .avatarDropzone {
	min-height: unset;
	padding: 1.5rem;
	margin-bottom: 0.5rem;
}

.avatarPaper .buttonContainer {
	width: 100%;
	margin-top: 1.5rem;
	display: grid;
	grid-template-columns: 3fr 2fr 3fr;
	grid-template-areas: "prev space next"
}

.avatarPaper .buttonContainer .prev
{
	grid-area: prev;
}

.avatarPaper .buttonContainer .next
{
	grid-area: next;
}

.avatarPaper .gottenImage {
	border-radius: 100%;
	max-width: 250px;
}