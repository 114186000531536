.account-title {
  margin-top: 1em;
}

.login-block {
  max-width: 90vw;
  margin: auto;
}

.login-input-label-div {
  width: 100%;
  max-width: 70vw;
  margin: 0 auto;
  margin-bottom: 18px;
}

.login-input-label-div:last-child {
  margin-top: 32px;
}

.login-input-label {
  text-align: left;
}

.login-input {
  width: 50%;
  min-width: 300px;
  height: 30px;

  padding: 0px 8px;

  text-align: center;
  font-size: 15px;
}

.map-management-button {
  height: 2.5em;
}

.management-title {
  margin-top: 1em;
}

.management-block {
  width: 800px;
  max-width: 90vw;
  margin: auto;
  margin-bottom: 40px;
}

.management-input-label-div {
  width: 80%;
  max-width: 70vw;
  margin: 0 auto;
  margin-bottom: 8px;
}

.management-input-label {
  text-align: left;
}

.management-input {
  width: 50%;
  min-width: 300px;
  height: 30px;

  padding: 0px 8px;

  text-align: center;
  font-size: 15px;
}

.management-table-input-field {
  width: 100%;
}

.moreButton
{
  display: inline-block;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  color: blue;
}

.moreButton:active
{
  color: #089EC8;
}

.poweredLink
{
  margin-bottom: 0;
  margin-top: 1.5em;
  font-size: 0.66em;
  text-align: center;
  color: inherit;
  display: block;
}

.poweredLink span
{
  color: #089EC8;
}

.poweredLink span:hover
{
  text-decoration: underline;
}

.headerLink
{
  width: 50%;
}

@media screen and (orientation:portrait)
{
	.headerLink
	{
    width: 100%;
	}
}