*, *:before, *:after {
  box-sizing: border-box;
}

.App
{
	height: 100vh;
	width: 100vw;
	scroll-behavior: smooth;
	overflow: hidden;
}

.content
{
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay
{
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	cursor: pointer;
}

.overlay button
{
	color: white;
	position: absolute;
	top: 25px;
	right: 25px;
}

.overlay button:focus
{
	outline: none;
}

.content>.MuiPaper-root, .content>.accountContainer
{
	z-index: 1;
	max-height: 75vh;
	width: 75vw;
}

.App .content button.cancelButton, .App .content button.logOut
{
	margin-top: 1em;
}

a
{
	text-decoration: none;
}