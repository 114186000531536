/* html
{
    scroll-behavior: smooth;
} */

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 15pt;
  line-height: 1.6;
  color: #666666;
}

th, td {
	vertical-align: middle;
	text-align: center;
	border: none;
}

@media only screen and (max-device-width : 640px) {
  body {
    font-size: 13pt;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: white;
  font-size: 14px;
  word-break: break-word;
}

img {
  width: 100%;
}

h1, h2, h3, h4 {
  color: #0D2E4C;
  font-weight: bold;
}

/* Hack to counter-act bootstrap css */
a:hover {
  text-decoration: none;
}

a.MuiButton-containedPrimary:hover {
  color: #fff;
}

a.MuiButton-outlinedPrimary:hover {
  color: #089EC8;
}

h4 {
  line-height: 1.6;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}