html, body
{
  background-color: rgba(0,0,0,0)
}

.account
{
  max-width: 75vw;
  display: flex;
  flex-direction: column;
}

.account .header
{
  height: inherit;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0D2E4C;
  padding: 1em;
  flex-direction: column;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.account .header img
{
  max-height: 90%;
  max-width: 75%;
  -webkit-user-drag: none;
}

.account .header p
{
  margin: 0;
  position: relative;
  top: 3em;
  color: white;
  font-size: 0.80rem;
}

.account .content
{
  height: inherit;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.account .content h1
{
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 1em;
}

.account .content form
{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px;
}

.account .content input
{
  padding: 0.5em;
}

.account .MuiTextField-root
{
  margin-bottom: 1em;
}


.account .content input:-webkit-autofill,
.account .content input:-webkit-autofill:hover,
.account .content input:-webkit-autofill:focus
{
  -webkit-box-shadow: 0 0 0px 1000px #089ec840 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.account .content .links
{
  margin-top: 1em;
}

.account .content .links a
{
  display: block;
  font-size: 1.15rem;
  color: #089EC8;
}

.account .content .links a:hover
{
  text-decoration: underline;
}

.account .content .buttons button
{
  margin: 0.5em 0;
}

.account .content .buttons .MuiButton-colorInherit span
{
  color: #0D2E4C;
}


.account .succes
{
  text-align: center;
  width: 100%;
}

.account .succes p
{
  margin-top: 0;
  font-size: 1.1rem;
}

@media screen and (max-width:665px) and (orientation:portrait)
{
  .account
  {
    overflow: auto;
  }

  .account .header
  {
    max-height: calc(75vh - 300px);

  }

  .account .header img
  {
    max-height: calc(calc(75vh - 300px)/4);
  }

  .account .header p
  {
    font-size: calc(1vh + 2.55px);
    margin-top: calc(1vh + 5px);
  }

  .account .content
  {
    min-height: 300px;
  }
}

@media screen and (max-height: 450px)
{
  .loginPaper
  {
    overflow: scroll;
  }

  .account .header{
    display: none;
  }

  .account .content
  {
    padding: 1em;
  }
}

@media screen and (max-height: 450px) and (min-width: 600px) and (orientation:landscape)
{
  .account .header{
    display: flex;
  }

  .account .content
  {
    padding: 1em;
  }
}


@media screen and (orientation:landscape) and (min-width: 600px){
  .account
  {
    flex-direction: row;
  }

  .account .header
  {
    width: 50%;
  }

  .account a .header
  {
    height: 100%;
    width: 100%;
  }

  .account .content
  {
    width: 50%;
  }
}